
.Technologies {
  background-color: #8300C1;
  position: relative;
  //background-image: url("../../assets/images/union.png")
  //background-repeat: round
 }  //background-size: cover

.Technologies {
  .container-fluid {
    &:before, &:after {
      content: '';
      background-image: url("../../assets/images/crop-lines.svg");
      background-size: cover;
      width: 100%;
      height: 28px;
      position: absolute; }
    &:before {
      top: -20px;
      left: 0; }
    &:after {
      bottom: -20px;
      left: 0;
      transform: rotate( 180deg ); } } }

.sparki {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  .sparki-item {
    .technol-text {
      font-size: 16px;
      line-height: 21.6px;
      color: #fff; } }


  img {
    margin: 0 auto;
    padding-bottom: 15px; }
  .sub {
    color: white;
    font-size: 30px; } }
.tech-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.box {
  margin: 20px 20px; }
.tech-list {
  list-style-image: url("../../assets/images/Vector 91.svg"); }












