.nav-linkd {
    background: none;
    border: none;
    color: #8300C1;
    font-size: 18px; }
.nav-linkd.active {
    font-size: 18px;
    color: white;
    text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1; }
.nav-linkd.noactive {
    box-shadow: none !important; }
.nav-linkd:hover {
    color: #fff;
    text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1; }
