.Create {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  &:before {
    content: '';
    background-image: url("../../assets/images/Ellipse_3.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 861px;
    height: 837px;
    position: absolute;
    left: 0;
    z-index: 0; }
  .picture {
    display: flex;
    justify-content: center;
    align-items: center; }
  .future {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    h1 {
      font-size: 60px;
      line-height: 110%; }
    p {
      color: #fff;
      font-size: 25px;
      line-height: 110%;
      margin-bottom: 50px; }
    .bor {
      text-decoration: none;
      color: #fff;
      border: 1px solid #fff;
      padding: 11px 50px;
      margin: 0;
      width: max-content;
      &:hover {
        color: white;
        text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
        border-color: #8300C1; } } }
  #tsparticles {
    & canvas {
      position: relative;
      width: 596px !important;
      height: 443px !important; } } }
ul {
  margin-top: 8px; }

@media screen and (max-width: 1440px) {
  .Create {
    .picture {
      justify-content: center; } } }
@media screen and (max-width: 1366px) {
  #tsparticles {
    transform: scale(0.8); } }
@media screen and (max-width: 991px) {
  #tsparticles {
    transform: scale(1); } }
@media screen and (max-width: 575px) {
  #tsparticles {
    transform: scale(0.8); } }
@media screen and (max-width: 475px) {
  #tsparticles {
    transform: scale(0.6); }
  .Create {
    padding-top: 180px; } }
@media screen and (max-width: 375px) {
  #tsparticles {
    transform: scale(0.5); } }
@media (max-width: 768px) and (max-height: 1024px) {
  .Create {
    height: auto;
    .picture {
      margin: 10px 0; } } }
