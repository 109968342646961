@import "../helpers/_mixins";

.whiteOne {
  @include xlarge-text; }
.whiteTwo {
  @include large-text; }
.whiteSubtitle1 {
  @include subtitle-1; }
.whiteSubtitle2 {
  @include subtitle-2; }
.whiteFiveSubtitle3 {
  @include subtitle-3; }
.whiteSixMenu {
  @include menu-sub; }
.whiteSevenBody1 {
  @include body-sub; }
.whiteElevenButton1 {
  @include button-sub; }
.whiteEightButton2 {
  @include button-sub-2; }
.whiteTen {
  @include caption-sub; }

.bor {
  @include shadow-a;
  margin-top: 50px;
  padding: 11px 45px; }
.hopper {
  @include text-hop; }
.fote {
  @include caption-sub;
  padding-top: 20px; }
.block-company {
  color: #8300C1;
  font-size: 60px;
  font-weight: 700; }
.link-bor {
  margin-top: 60px; }
.body2 {
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 300;
  font-family: "Raleway", sans-serif; }

@media screen and (max-width: 575px) {
  .whiteElevenButton1 {
    font-size: 14px; } }
