.rewiew {
    & .WhiteThree {
        line-height: 36px;
        color: #fff;
        font-weight: 600; }
    ul {
        list-style-image: url("../../assets/images/Vector 91.png");
        li {
            padding-top: 18px; } }
    & .rewiew-body {
        margin-top: 75px;

        & .image-review-two {
            margin: 0 auto; }
        & img {
            max-width: 100%; }
        & .whiteSubtitle1 {
            padding-bottom: 13px; }
        & .whiteFiveSubtitle3 {
            padding-bottom: 15px; } } }
