@mixin button-lener {
    background: none;
    color: #fff;
    font-size: 25px;
    border: none;
    border: 1px solid #fff;
    padding: 11px 42px;
    margin-top: 59px;
    margin-bottom: 81px;
    &:hover {
        box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
        text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; } }
