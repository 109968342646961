.modals {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  z-index: 10; }
.modals.active {
  opacity: 1;
  pointer-events: all; }

.modal__content {
  position: relative;
  background-color: transparent;
  max-width: 1000px;
  height: max-content;
  transform: scale(0.5);
  transition: 0.4s all;
  z-index: 2;
  border: 30px solid #fff;
  -moz-border-image: url("../../assets/images/vector_modal.svg") 30;
  -webkit-border-image: url("../../assets/images/vector_modal.svg") 30;
  -o-border-image: url("../../assets/images/vector_modal.svg") 30;
  border-image: url("../../assets/images/vector_modal.svg") 30;
  .img {
    height: auto; }
  .modal-body {
    background-color: #08031d; }
  .bor {
    width: max-content;
    &:hover {
      cursor: pointer; } }
  .modal_title {
    height: 100%;
    h2 {
      margin: 0 0 20px; }
    p {
      margin-bottom: 30px !important; } } }
.modal__content.active {
  transform: scale(1); }

@media screen and (max-width: 767px) {
  .modal__content {
    .whiteSubtitle2 {
      font-size: 25px;
      line-height: 1.2; }
    img {
      height: 250px; } } }

@media screen and (max-width: 575px) {
  .modal__content {
    .modal-body {
      flex-direction: column-reverse; }
    .modal_title {
      align-items: center;
      p {
        text-align: center; } } } }

@media screen and (max-width: 575px) {
    .modal__content {
      .modal_title {
        h2 {
          font-size: 25px;
          line-height: 1.2; }
        p {
          font-size: 20px;
          line-height: 1.2; } } } }

@media screen and (max-width: 575px) {
  .modal__content {
    .modal_title {
      h2 {
        font-size: 20px;
        line-height: 1.2; }
      p {
        font-size: 16px;
        line-height: 1.2; } } } }

