@import "../helpers/_button";
@import "../helpers/_mixins";
.request {
    padding: 220px 0 190px 0;
    .container-fluid {
        width: 100%;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto; } }
.formControlerror, .formControl.error {
    border-bottom: 1px solid #890000 !important;
    label {
        color: #890000; }
    input, textarea {
        color: #890000 !important; } }
.required {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    margin-top: 60px;
    input {
        width: 50%; }
    textarea {
        width: 50%; } }
form {
    background: none;
    select {
        background-color: transparent;
        color: #fff;
        border: none;
        outline: 0;
        option {
            background-color: #41106e; } }
    input {
        background: none;
        border: none;
        color: #000; }
    .select-form {
        width: 50%;
        color: #000;
        background:  none;
        border: none;
        @include button-sub;
        option {
            background-color: rgb(65, 16, 110); }
        .css-1okebmr-indicatorSeparator {
            display: none; }
        .css-1s2u09g-control {
            background-color: transparent !important;
            border: none; }
        .css-qc6sy-singleValue {
            color: #fff; }
        .css-26l3qy-menu {
            background-color: #41106e !important;
            div {
             color: #ffffff; } } }
    .css-1pahdxg-control {
        background-color: rgb(65, 16, 110);
        border-color: #8300C1;
        box-shadow: 0 0 0 1px #8300c1;
        &:hover {
            border-color: #41106e; } }
    .css-1n7v3ny-option, .css-yt9ioa-option, .css-9gakcf-option, .css-yt9ioa-option, .css-yt9ioa-option {
        background-color: #41106e;
        &:active {
            background-color: #8300c161; } }
    .css-yt9ioa-option:active {
        background-color: #8300c161;
        .css-1pahdxg-control {
            background-color: rgba(8, 3, 29, 0.6) !important;
            box-shadow: none;
            border-color: transparent;
            &:hover {
                border-color: transparent; } } }


    .lener {
        &:disabled {
            color: rgb(130, 129, 141);
            border: 1px solid rgb(130, 129, 141);
            &:hover {
                box-shadow: none;
                text-shadow: none; } } }

    textarea {
        background: none;
        border: none;
        color: #fff;
        &:focus {
            outline: none; }
        &::-webkit-scrollbar {
            width: 0.5em; }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
        &::-webkit-scrollbar-thumb {
            background-color: #CC00FF;
            outline: 1px solid #CC00FF; }
        &::-webkit-scrollbar-corner {
            background: transparent;
            border: 0; }
        &::-webkit-resizer {
            border: 0; } }

    .formControl {
        display: flex;
        margin-bottom: 10px; }

    .formControlerror {
        & span {
            color: red; }
        & img {
            max-width: 50%; } } }
.invalid {
    border: 2px solid red; }

.valid {
    border: 2px solid green; }

.forms {
    background-image: url("../../assets/images/lineRequest.png");
    background-repeat: round;
    padding-left: 238px;
    padding-right: 68px;
    h2 {
        @include subtitle-3;
        font-weight: bold;
        margin-top: 54px; }
    .ital {
        @include subtitle-2;
        margin-bottom: 3%; } }
.dev {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 90%; } }

.lener {
    @include button-lener; }

@media screen and (max-width: 1400px) {
    form {
        .formControlerror {} } }
@media screen and (max-width: 767px) {
    .dev img {
        max-width: 90%; }
    .required {
        input, textarea {
            padding: 10px; } } }

@media screen and (max-width: 575px) {
    .forms {
     & .ital {
         font-size: 22px;
         line-height: 26px; }
     .required {
         flex-direction: column;
         align-items: baseline; } }
    .dev img {
        margin-top: 40px; }
    form {
        & .formControl, .formControlerror {
            width: 100%; } } }
