.Portfolio {
  margin-bottom: 250px;
  margin-top: 136px;
  & .bor {
    margin-top: 30px; }
  & .block-port {
    padding: 25px 30px;
    background-image: url("../../assets/images/Vector 115.png");
    background-repeat: round; }
  & .whiteTwo {
    margin-bottom: 30px; }
  & .bottom-block {
    margin-bottom: 40px; } }


.link-image {
  .kak {
    display: none; } }
.link-image.active {
  pointer-events: none;
  img:hover {
    filter: brightness(.2) !important; }
  .kak {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 66px;
    font-weight: bold;
    color: #FF0A0A;
    z-index: 1;
    border-radius: 40px;
    background: transparent;
    border: 1px solid #FF0A0A;
    top: 40%;
    right: 40%;
    width: 200px; } }

.project {
  position: relative; }

.nav-link {
  margin-top: 30px;
  background: none;
  color: #8300C1;
  box-shadow: 0 0 6px rgba(131, 0, 193, 0.5); }
.nav-pills .nav-link.active {
  background: none;
  color: #8300C1; }
.nav-link:hover {
  color: #fff;
  box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; }

@media screen and (max-width: 991px) {
  .Portfolio {
    margin: 0px;
    .achievements {
      margin-top: 50px; } } }
@media screen and (max-width: 575px) {
  .achievements {

    div:nth-child(3) {
      margin-bottom: 40px; } } }
