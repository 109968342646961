@media (max-width: 800px) {
  .Vacancies {
    & .vacancies-text {
      & .whiteSevenBody1 {
        font-size: 16px;
        padding: 0; }
      & .whiteElevenButton1 {
        font-size: 16px; }
      & .whiteEightButton2 {
        padding-top: 0; }
      & p {
        margin-bottom: 10px; } } } }
