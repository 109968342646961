.TechBack {
  .tabs-link {
    a:hover {
      color: #ffffff; } } }
.TechnoFriend {
  .nav {
    display: flex;
    align-items: center;
    .nav-item {
      margin-left: 34px; }
    & li:first-child {
      margin-left: 0; } }
  .tab-content {
    height: auto; } }

@media (min-width: 320px) {
  .TechBack {} }

@media (max-width: 1650px) {
  .n-techno {
    .TechnoFriend {

      .tab-content {
        h3 {
          font-size: 30px; }
        .whiteFiveSubtitle3 {
          font-size: 20px; } } } } }


@media (min-width: 1400px) {
  .TechnoFriend {
    & nav {
      flex-direction: column; }
    & .tab-content {} } }
@media (max-width: 1330px) {
  .n-techno {
    .TechnoFriend {
      .tab-content {
        .whiteFiveSubtitle3 {
          padding-bottom: 12px; } } } } }
@media (max-width: 1201px) {
  .TechnoFriend {
    & nav {
      flex-direction: row; } } }
@media (max-width: 1199px) {
  .n-techno {
    .TechnoFriend {
      .tab-content {
        height: auto; }
      .tab-body {
        background: #8300C1;
        position: relative;
        &:before, &:after {
          content: '';
          background-image: url("../../assets/images/crop-lines.svg");
          background-size: cover;
          width: 100%;
          height: 28px;
          position: absolute; }
        &:before {
          top: -15px;
          left: 0; }
        &:after {
          bottom: -15px;
          left: 0;
          transform: rotate( 180deg ); } } }
    .Technolodies {
      & .main-test-image {
        display: flex !important; }
      & .two-image-test {
        display: none; } }
    .TechnoFriend {

      .nav {
        flex-direction: initial; }
      & .tab-content {
        & .whiteFiveSubtitle3 {
          padding-bottom: 5px;
          font-size: 18px; } }
      .tabs-services {
        margin-top: 60px; } }
    .n-techno .TechBack .tabs-small {
      width: 32% !important; }
    .n-techno .TechnoFriend .nav-item .nav-link {
      width: 90%; }
    .n-techno .TechnoFriend .grup {
      margin-left: 31px; } } }
@media (max-width: 991px) {
  .Technolodies {
    & .main-test-image {
      display: none !important; }
    & .two-image-test {
      display: block; } } }
@media (max-width: 576px) {
  .n-techno {
    .TechnoFriend {
      .tab-content {
        h3 {
          font-size: 22px;
          padding-bottom: 0;
          margin-bottom: 20px; } } }
    & .whiteTwo {
      padding-top: 180px;
      & .technolodies-text {
        & .whiteFiveSubtitle3 {
          font-size: 16px; } } } } }











