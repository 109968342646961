.PortfolioPage {
  padding: 160px 0 200px 0;

  & .first-image {
    &::before {
      content: url("../../assets/images/Ellipse.png");
      position: absolute;
      z-index: 0;
      top: 0;
      right: 50px; }
    & img {
      z-index: 1;
      max-width: 100%; } }
  .portfolio-image {
    img {
      max-width: 100%; } }
  .list-company {
    box-shadow: #8300C180;
    & li {
      margin-top: 50px;
      padding: 15px 30px;
      box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
      margin-right: 35px; } }
  & .WhiteThree {
    color: #fff;
    padding-top: 300px;
    font-size: 35px;
    padding-bottom: 77px; }
  & .portfolio-project {
    padding-top: 400px;
    & .whiteTwo {
      padding-bottom: 60px; }
    & .whiteSubtitle1 {
      padding-bottom: 30px; }
    & .whiteEightButton2 {
        padding-top: 25px; }
    & .whiteElevenButton1 {
      padding-top: 25px;
      font-weight: 700; }
    & ul {
      list-style-image: url("../../assets/images/Vector 91.png"); } }
  & .Clients {
    margin-top: 300px; }
  & img {
    max-width: 100%; }
  & .whiteThree {
    color: #ffffff;
    font-size: 36px;
    font-weight: 500;
    line-height: 43px;
    padding-bottom: 50px; } }
.LastProject {
  margin-top: 190px;
  & .project-sm {
    & img {
      max-height: 250px; } }
  & .project-lg {
    & img {
      max-height: 480px; } }
  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    & .whiteTwo {
      padding-bottom: 50px; }
    & .project {
      background: linear-gradient(0deg, rgba(8, 3, 29, 0.75), rgba(8, 3, 29, 0.75)); }

    & .project-bkk, .project-cripto, .project-catch {
      padding-right: 0; }
    & img {
      opacity: 0.4;
      padding-bottom: 30px;
      max-width: 100%;
      min-height: 100%; }
    & img:hover {
      opacity: 1;
      transition: .4s all; } } }
.port-img {
  width: 100%; }
.image-stream, .image-bkff {
  z-index: 1; }
@media (max-width: 767px) {
  .link-image {
    width: 100% !important;
    display: block !important;
    img {
      width: 100% !important; } }

  .LastProject {
    & .project-sm {
      & img {
        max-height: 350px; } }
    & .project-lg {
      & img {
        max-height: 350px; } } } }

@media (max-width: 575px) {

  .LastProject {
    & .project-sm {
      & img {
        max-height: 230px; } }
    & .project-lg {
      & img {
        max-height: 230px; } } } }

@media (max-width: 425px) {

  .LastProject {
    & .project-sm {
      & img {
        max-height: 180px; } }
    & .project-lg {
      & img {
        max-height: 180px; } } } }

