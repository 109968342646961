@media (min-width: 320px) {
  .PortfolioPageAgro, .PortfolioPageBKKFU, .PortfolioPageCoin, .PortfolioPageCrypto, .PortfolioPageDefiPro, .PortfolioPageStockPoint, .PortfolioPageStream, .PortfolioPageTitan, .PortfolioPageYour {
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    max-width: 100%;
    .image-titan {
       width: 430px; }
    .first-tab {
       display: none; }
    .secondary-image {
       padding: 40px 0 140px 0;
       & img {
          max-width: 100%; } }
    .first-image {
       padding-top: 50px; }
    .page-body {
       padding-top: 150px; } } }
@media (min-width: 576px) {
  .PortfolioPageAgro, .PortfolioPageBKKFU, .PortfolioPageCoin, .PortfolioPageCrypto, .PortfolioPageDefiPro, .PortfolioPageStockPoint, .PortfolioPageStream, .PortfolioPageTitan, .PortfolioPageYour {
    .first-image {
        padding-top: 0; } } }

@media (min-width: 992px) {
  .PortfolioPageAgro, .PortfolioPageBKKFU, .PortfolioPageCoin, .PortfolioPageCrypto, .PortfolioPageDefiPro, .PortfolioPageStockPoint, .PortfolioPageStream, .PortfolioPageTitan, .PortfolioPageYour {
    .page-body {
      padding-top: 220px; }
    .image-agros {
      display: none; }
    .first-tab {
      display: block;
      max-width: 100%;
      & img {
        max-width: 100%; } } } }

@media (max-width: 560px) {
  .PortfolioPageAgro, .PortfolioPageBKKFU, .PortfolioPageCoin, .PortfolioPageCrypto, .PortfolioPageDefiPro, .PortfolioPageStockPoint, .PortfolioPageStream, .PortfolioPageTitan, .PortfolioPageYour {
    .whiteSubtitle1 {
      font-size: 25px;
      line-height: 30px; }
    .whiteSixMenu {
      font-size: 16px;
      line-height: 20px; }
    .WhiteThree {
      font-size: 25px;
      line-height: 30px; }
    .Another {
      .whiteTwo {
        padding-top: 10px; }
      .card-agro {
        margin-top: 20px; } } } }
