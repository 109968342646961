@mixin xlarge-text {
    font: {
        size: 80px;
        family: Raleway, sans-serif;
        weight: 700; }
    color: #fff;
    line-height: 96px;
    padding: {
        bottom: 35px; } }
@mixin large-text {
    font: {
        size: 50px;
        family: Raleway, sans-serif;
        weight: bold; }
    color: #fff;
    line-height: 60px; }
@mixin subtitle-1 {
    font: {
        size: 30px;
        family: Raleway, sans-serif;
        weight: 500; }
    color: #fff;
    line-height: 36px; }
@mixin subtitle-2 {
    font: {
        size: 30px;
        family: Raleway, sans-serif;
        weight: 300;
        style: italic; }
    color: #fff;
    line-height: 36px; }
@mixin subtitle-3 {
    font: {
        size: 25px;
        family: Raleway, sans-serif;
        weight: 300; }
    color: #fff;
    line-height: 30px; }
@mixin menu-sub {
    font: {
        size: 18px;
        family: Raleway, sans-serif;
        weight: 400; }
    color: #fff;
    line-height: 21.6px; }
@mixin body-sub {
    font: {
        size: 25px;
        family: Raleway, sans-serif;
        weight: 300; }
    color: #fff;
    line-height: 21.6px; }
@mixin button-sub {
    font: {
        size: 18px;
        family: Raleway, sans-serif;
        weight: 500; }
    color: #fff;
    line-height: 30px; }

@mixin button-sub-2 {
    font: {
        size: 18px;
        family: Raleway, sans-serif;
        weight: 600; }
    color: #fff;
    line-height: 21.6px; }
@mixin caption-sub {
    font: {
        size: 14px;
        family: Raleway, sans-serif;
        weight: 400; }
    color: #fff;
    line-height: 16.8px; }
@mixin shadow-a {
    font: {
        size: 25px; }
    color: #fff;
    border: 1px solid #fff;
    &:hover {
        color: white;
        text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
        border-color: #8300C1; } }
@mixin text-hop {
    font: {
        size: 18px;
        weight: bold; }
    color: #fff; }


