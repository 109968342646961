.Footer {
    background-image: url("../../assets/images/union.png");
    padding: 87px 0 28px 0;
    & img {
        filter: brightness(1.3); }
    ul {
        margin-top: 9%; }
    .bottom-text {
        margin-bottom: 0; }
    .links {
        a {
            padding-right: 20px; }
        a:hover {
            color: #fff; } }
    .footer-bot {
        margin-top: 60px;
        margin-bottom: 18px; } }
.simplex {
    .simpl {
        margin-bottom: 29px; } }
.fotMenu {
    ul {
        display: flex;
        a {
            color: #fff;
            font-size: 18px; }
        a:hover {
            color: #fff;
            text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; } } }

.social {}
.contact {
    display: flex;
    flex-direction: column;
    justify-content: center; }
.footerss {
    padding: 56px 0 20px 0;
    & .simplex-logo {
        margin: 0 auto; }
    .simple {
        margin: 0 auto;
        padding-top: 20px; }
    ul {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        li {
            padding-top: 10px;
            font-size: 16px;
            margin-bottom: 20px;
            padding-right: 15px; } } }
.sociale {
    margin-top: 5px;
    justify-content: center;
    margin-bottom: 10px; }
.fotmenus {
    & a:hover {
        text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; } }

@media screen and  (max-width: 991px) {
    .Footer {
        & .footer-item {
            flex-direction: column;
            align-items: center; }
        & .simplex, .contact {
            align-items: center;
            justify-content: center; }
        & .simplex {
            margin-bottom: 30px; }
        & .footer-bot {
            margin-top: 40px; } } }
@media screen and (max-width: 575px) {
    .whiteElevenButton1 {
        font-size: 14px;
        line-height: 1.1; } }
