.Vacations {
  .request {
    padding: 0 0 190px 0; } }
.Content {
  padding: 140px 0 256px 0;
  & .whiteSubtitle2 {
    padding-bottom: 60px; }
  .contact-content {
    padding-bottom: 165px; }
  .contact-contentTwo {
    padding-bottom: 328px; }
  .contact-text {
    padding-bottom: 131px; }
  .soc-media {
    & img {
      padding-right: 42.5px; } }


  & .contact-tab {
    background-image: url("../../assets/images/jap-hight_2.svg");
    background-size: cover;
    background-repeat: round;
    padding: 40px 45px;
    height: 255px;
    & .whiteTwo {
      font-size: 28px;
      margin-bottom: 0; } }
  & .contact-two {
    background-image: url("../../assets/images/jap-hight_2.svg");
    background-size: cover;
    background-repeat: round;
    height: 255px; }
  & .contact-width {
    word-break: break-all; }

  & .contact-bottom {
    justify-content: flex-end;
    margin-top: 30px; }
  & .first-container {
    margin-right: 30px; }

  & .contact-tabs {
    padding: 40px 60px;
    background-image: url("../../assets/images/vector 115.svg");
    background-repeat: round;
    background-size: cover;
    height: 255px; } }
.social {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url("../../assets/images/social_links.svg");
  background-size: cover;
  &.facebook {
    background-position-x: 272px; }
  &.instagram {
    background-position-x: 202px; }
  &.twitter {
    background-position-x: 132px; }
  &.behance {
    background-position-x: 60px; } }

#select_type {
  color: rgba(255, 255, 255, .6);
  -webkit-appearance: none;
  position: relative;
  &:focus {
    outline: none; } }



@media screen and (max-width: 1440px) {
  .Content {
    & .contact-tab, .contact-tabs {
      height: 220px; }
    & .contact-bottom {
      margin-top: 40px; }
    & .contact-tab .whiteTwo {
      font-size: 28px; } } }
@media screen and (max-width: 1365px) {
  .Content {
   & .contact-top, .contact-bottom {
     justify-content: center; } } }
@media screen and (max-width: 1280px) {
  .Content {
    & .contact-tab .whiteTwo {
      font-size: 26px; } } }
@media screen and (max-width: 1176px) {
  .Content {
    & .contact-tab .whiteTwo {
      font-size: 22px;
      line-height: 40px; }
    & .contact-tab, .contact-tabs {
      padding: 30px 50px; }
    & .whiteSubtitle1 {
      font-size: 24px; } } }
@media screen and (max-width: 1060px) {
  .Content {
    & .contact-tab .whiteTwo {
      font-size: 20px; } } }
@media screen and (max-width: 991px) {
  .Vacations {
    & .request {
      padding: 0 0 130px 0; } }
  .Content {
    padding: 140px 0 140px 0;
    & .first-container {
      margin-right: 0; }
    & .contact-tab, .contact-tabs {
      width: 500px;
      margin-top: 30px; }
    & .contact-bottom {
      margin-top: 0 !important; }
    & .contact-tab .whiteTwo, .whiteSubtitle1 {
      font-size: 28px; } } }
@media screen and (max-width: 767px) {
  .Content {
    & .whiteOne {
      font-size: 50px;
      line-height: 60px; }
    & .whiteSubtitle2 {
      padding-bottom: 60px;
      font-size: 30px;
      line-height: 36px; } } }
@media screen and (max-width: 576px) {
  .Content {
    justify-content: center;
    & .contact-tab, .contact-tabs {
      justify-content: center;
      height: 180px; }
    & .contact-tab .whiteTwo, .whiteSubtitle1 {
      font-size: 24px;
      line-height: 1; }
    & .contact-tab, .contact-tabs {
      width: 450px; }
    & .social {
      width: 40px;
      height: 40px;
      &.facebook {
        background-position-x: 408px; }
      &.instagram {
        background-position-x: 133px; }
      &.twitter {
        background-position-x: 313px; }
      &.behance {
        background-position-x: 40px; } }
    & .whiteOne {
      font-size: 35px;
      line-height: 1.2;
      padding-bottom: 20px; }
    & .whiteSubtitle2 {
      font-size: 22px;
      line-height: 1.1;
      padding-bottom: 20px; } } }
@media screen and (max-width: 475px) {
  .Content {
    & .contact-tab, .contact-tabs {
      width: 360px;
      padding: 20px 30px; }
    & .contact-tab .whiteTwo, .whiteSubtitle1 {
     font-size: 16px; } } }
@media screen and (max-width: 375px) {
  .Content {
    & .contact-tab, .contact-tabs {
      width: 300px;
      height: 130px; } } }

