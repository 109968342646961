* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body {
  height: 100%;
  font-size: 100%;
  font-family: 'Raleway', sans-serif;
  line-height: 1.4; }
#root {
  overflow: hidden; }

img {
  display: block;
  height: auto; }

a {
  color: white;
  text-decoration: none;
  transition: all .2s linear; }
.wrapper {
  background: #08031D;
  bg-image: url("../../assets/images/bg.jpg"); }

.color {
  &-red {
    color: rgba(137, 0, 0, 1); } }

.container-fluid {
  padding: 0 238px 0 238px; }
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #05041e inset;
  -webkit-text-fill-color: #fff; }

@media (max-width: 1650px) {
  .container-fluid {
    padding: 0 120px 0 120px; } }
@media (max-width: 1495px) {
  .container-fluid {
    padding: 0 120px 0 120px; } }
@media (max-width: 1440px) {
  .container-fluid {
    padding: 0 70px 0 70px; } }
@media (max-width: 1330px) {
  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 2.5rem);
    padding-left: var(--bs-gutter-x, 2.5rem);
    margin-right: auto;
    margin-left: auto; } }
//@media (max-width: 991px)
//  .container-fluid
//      padding-left: 70px
@media (max-width: 576px) {
  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto; } }
@media screen and (max-width: 575px) {
  .Footer {
    .contact {
      a {
        margin-bottom: 10px; } } } }
