.Header {
  position: fixed;
  padding-top: 51px;
  padding-bottom: 30px;
  background: linear-gradient(180deg, rgb(5,4,30) 60.71%, rgba(8, 3, 29, 0) 100%);
  height: 140px;
  z-index: 2; }
.logo {
  z-index: 12;
  & h2 {
    padding-left: 34px;
    color: #fff;
    font-size: 30px;
    font-weight: 500; } }
.lang-body {
  display: flex;
  align-items: center;
  justify-content: space-around; }
.navbarNav {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  .whiteElevenButton1.active {
    color: #ffffff;
    font-weight: 700;
    text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; }
  a:hover {
    color: #fff;
    text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; } }
.nav-links {
  a {
    padding-right: 15px; } }

.lang {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;

  & button {
    margin-top: 8px;
    background: none;
    border: none;
    color: #8300C1;
    font-size: 15px; }
  & button:active {
    font-size: 18px;
    color: white;
    text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1; } }
.burger {
  display: none; }
.hamburgerIcon {
  width: 30px;
  display: none;
  background-color: #fff;
  height: 3px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
  transition: all 1s ease; }


.hamburgerIcon:after, .hamburgerIcon:before {
  content: '';
  display: block;
  width: 20px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 5px;
  border-radius: 50px;
  box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
  transition: all 1s ease; }


.hamburgerIcon:after {
  bottom: -10px;
  left: 10px;
  box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
  transition: all 1s ease; }


.hamburgerIcon:before {
  bottom: 10px;
  left: -5px;
  width: 35px;
  box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
  transition: all .3s ease; }
@media (max-width: 991px) {
  .burger {
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center; }
  .hamburgerIcon {
    display: block;
    z-index: 14; }
  .burger.active {
    .hamburgerIcon {
      box-shadow: none;
      opacity: 1;
      height: 0;
      transition: all .1s ease; }
    .hamburgerIcon:before {
      transform: rotate(-45deg);
      left: -2px;
      bottom: -2px;
      box-shadow: none;
      width: 35px; }
    .hamburgerIcon:after {
      left: -1px;
      bottom: -2px;
      transform: rotate(45deg);
      width: 35px;
      transition: all .3s ease;
      box-shadow: none; } }
  .lang-body {
    display: none; }
  .navbarNav {
    display: none;
    list-style: none;
    text-align: center;
    background-color: #08031D;
    position: absolute;
    top: 65px;
    width: 100vw;
    height: calc(100vh - 65px);
    transform: translateX(-10000px);
    z-index: 10;
    transition: all 0.7s ease;
    padding-left: 0 !important; }
  .lang-body.active {
    display: flex; } }


.navbarNav.active {
  /* left: 0; */
  transform: translateX(-2.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  li {
    padding-bottom: 20px; }
  .whiteElevenButton1 {
    font-size: 36px; } }
@media (max-width: 576px) {
  .navbarNav.active {
      transform: translateX(-.75rem); } }

@media (max-width: 500px) {
  .navbar {
    padding-top: 20px; }
  .lang-body {
    position: absolute;
    top: -15px;
    left: 40%; } }
@media (max-width: 400px) {
  .lang-body {
    left: 35%; } }




