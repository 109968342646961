@media (max-width: 450px) {
  .About {
    .about-text {
      padding-top: 100px;
      & .whiteSubtitle2 {
        font-size: 24px;
        line-height: 25px; } } } }


@media (max-width: 575.98px) {
  .About {
    padding: 100px 0; } }



@media (max-width: 1400px) {
  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto; } }
