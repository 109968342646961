.Vacancies {
  padding-top: 220px;
  & .cannot-type {
    font-style: italic; }
  .vacancies-list {
    padding-top: {}
    & ul {
      padding-left: 0;
      list-style-type: none;
      & li {
        padding-right: 23px; } } }
  .vacancies-dev {
    padding: 30px 0 104px 0;
    & img {
      max-width: 100%; } }
  .vacanciesFront {
    .formFront {
      form {
        .input-select {
          width: 65.7%; } } } }
  & .vacancies-text {
    padding-top: 30px;
    padding-bottom: 30px;
    & .cannot-body-text {
      line-height: 120%; }
    & .whiteEightButton2 {
      padding-top: 24px; } }
  & .photo-border {
    img {
      border: 1px solid #8300C1; } } }
.dbs div:nth-child(2n) {
  flex-direction: row-reverse; }
.nav-tabs {
  border-bottom: none;
  .nav-link.active {
    background-color: transparent;
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate;
    color: #fff;
    box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .nav-link:hover {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; } }
.vacancies-front {
  margin-bottom: 70px; }
@media (max-width: 991px) {
  .front {
    margin-top: 80px; } }
@media (max-width: 576px) {
  .vacancies-dev {
    padding: 30px 0 60px 0 !important; } }





