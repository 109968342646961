.PortfolioPageBKKFU {
  & .first-image {
    &::before {
      content: url("../../assets/images/Ellipse.png");
      position: absolute;
      z-index: 0;
      top: 0;
      right: 50px; }
    & img {
      z-index: 1;
      max-width: 100%;
      filter: drop-shadow(-10px 10px 20px rgba(131, 0, 193, 0.3)); } }
  & .product {
    margin: 100px 0; }
  & .image-bkff {
    & img {
      margin-bottom: 42px;
      height: 42px;
      width: 157px; } }
  & .image-bkff-tablet {
    & img {
      max-width: 100%; } }

  & .whiteSubtitle1 {
    padding-bottom: 30px; }
  ul {
    list-style-image: url("../../assets/images/Vector 91.png");
    li {
      padding-top: 18px; } }
  & .whiteSevenBody1 {
    font-size: 18px;
    padding-left: 12px; }
  & .WhiteThree {
    color: #ffffff;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    padding-top: 30px; } }
.Another {
  background-color: #8300C1;
  position: relative;
  .container-fluid {
    &:before, &:after {
      content: '';
      background-image: url("../../assets/images/crop-lines.svg");
      background-size: cover;
      width: 100%;
      height: 28px;
      position: absolute;

      &:before {
        top: -20px;
        left: 0; }

      &:after {
        bottom: -20px;
        left: 0;
        transform: rotate(180deg); } } }
  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    & .card-agro {
      padding-bottom: 30px; } }
  & .link-image-bkk.active, .link-image-stock.active, .link-image-crypto.active, .link-image-titan.active, .link-image-coris.active,.link-image-catch.active, .link-image-stream.active {
    display: none; }
  & .link-image-bkk, .link-image-stock, .link-image-crypto, .link-image-titan, .link-image-coris,.link-image-catch, .link-image-stream {
    display: contents; } }

@media (max-width: 560px) {
  .PortfolioPageBKKFU {
    .page-body {
      padding-top: 170px; } } }
@media (min-width: 768px) {
  .first-tab {
    display: block; } }
