.Benefits {
  background-color: #8300C1;
  position: relative;
  margin-top: 50px;
  & .container-fluid {
    &:before, &:after {
      position: absolute;
      content: '';
      background-image: url("../../assets/images/crop-lines.svg");
      background-size: cover;
      width: 100%;
      height: 28px; }
    &::after {
      bottom: -20px;
      left: 0;
      transform: rotate(180deg); }
    &::before {
      top: -20px;
      left: 0; } }


  & .benefits-body {
    padding: 66px 0;
    align-items: baseline;
    & img {
      width: 91px; }
    & .benefits-title {
      color: #fff;
      font-size: 16px;
      line-height: 19.2px;
      text-align: center;
      .whiteSubtitle1 {
          word-break: break-all; } } } }
@media (max-width: 1439px) {
  .ben {
    margin-top: 30px; } }
@media (max-width: 575px) {
  .ben {
    &_item {
      &.benefits-item {
        flex-direction: column;
        text-align: center;
        img {
          margin: 0 auto; } } } } }
