.About {
  padding: 200px 0;
  & .about-text {
    padding-top: 10%;
    position: relative;
    & .text-img {
      position: absolute; }
    & .text-imgtwo {
      position: absolute;
      bottom: -10px;
      right: 0; }
    & p {
      padding: 20px 0 9px 22px; } }


  & .about-comment {
    padding-left: 22px; }
  img {
    max-width: 100%; } }

@media screen and (max-width: 575px) {
  .About {
    .whiteTwo {
      font-size: 35px; } } }
