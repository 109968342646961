.image-agro {
  z-index: 1; }


@media (max-width: 575px) {
  .PortfolioPage {
    padding: 160px 0 80px 0 !important;
    & .WhiteThree {
      padding-top: 200px;
      padding-bottom: 0; }
    & .portfolio-project {
      padding: 100px 0 !important;
      & .whiteTwo {
        font-size: 22px;
        padding-bottom: 15px; }
      & .whiteSubtitle1 {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 0;
        line-height: 25px; }
      & .whiteSevenBody1 {
        font-size: 16px; } }
    .request {
      padding: 120px 0; }
    .Clients {
      padding: 58px 0;
      & .whiteThree {
        font-size: 30px;
        line-height: 34px; }
      & .clients-company {
        width: 50%; } }
    .LastProject {
      & .whiteTwo {
        padding-top: 60px;
        font-size: 30px;
        padding-bottom: 24px;
        text-align: left !important; } } } }
@media (max-width: 764px) {
  .PortfolioPage {
    & .portfolio-text {
      & .whiteSubtitle2 {
        font-size: 22px;
        padding-bottom: 60px; } } } }



@media (max-width: 991px) {
  .PortfolioPage {
    padding: 120px 0 180px 0;
    & .LastProject {
      margin-top: 50px; }
    & .Clients {
      margin-top: 50px; }
    .portfolio-project {
      padding: 191px 0; }
    .portfolio-image {
      & .imageBot {
        display: none; } }
    & .two-mage {
      display: none; } } }

@media (min-width: 993px) {
  .PortfolioPage {
    & .LastProject {}
    & .Clients {
      margin-top: 185px; }
    & .imageBot {
      display: none; }
    & .two-mage {
      display: block; } } }
