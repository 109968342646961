.History {
  padding: 130px 0;
  & .whiteSubtitle1 {
    padding-bottom: 22px; }
  & .text-work {
    padding-top: 10%; }
  & .history-item {
    padding-top: 4.5%; }
  img {
    max-width: 100%; } }
