.Clients {
  margin-top: 44px;
  padding: 86px 0 20px 0;
  background-color: #8300C1;
  & .clients-bottom {
    margin-bottom: 70px; }
  & .clients-company {
    margin-bottom: 70px;
    & img {
      max-width: 100%; } }

  .container-fluid {
    position: relative;
    &:before, &:after {
      content: '';
      background-image: url("../../assets/images/crop-lines.svg");
      background-size: cover;
      width: 100%;
      height: 28px;
      position: absolute; }
    &:before {
      top: -100px;
      left: 0; }
    &:after {
      bottom: -35px;
      left: 0;
      transform: rotate( 180deg ); } } }
@media (max-width: 575px) {
  .clients_part {
    .container-fluid {
      &:before {
        top: -85px !important; }
      &:after {
        bottom: -72px !important; } } }

  .Clients {
    .container-fluid {
      &:before {
        top: -95px; }
      &:after {
        bottom: -47px; } } } }
