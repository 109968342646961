
@media (min-width: 319px) {
    .container-fluid {}
    .Header {
        .logo {
            h2 {
                font-size: 25px;
                padding-left: 5px; } }
        .lang-body {
            justify-content: center !important;
            //position: absolute
            //right: 0
            //top: 0
            & .nav-linkd {
                padding-right: 20px; } }
        .lang {
            display: flex;
            margin-top: 10px;
            position: relative; } }

    .Create {
        padding: 400px 0 50px 0;
        .whiteSubtitle1 {
            font-size: 18px; }

        .bor {
            padding: 9px 35px;
            font-size: 20px; }
        .picture {
            padding-top: 50px;
            position: relative;
            & video {
                max-width: 100%; } } }

    .Portfolio {
        .whiteTwo {
            font-size: 30px;
            line-height: 40px !important; }
        .whiteSevenBody1 {
            font-size: 18px; }
        .link-bor {
            margin-bottom: 50px; }
        .achievements {
            .block-company {
                font-size: 29px; }
            .whiteSubtitle1 {
                font-size: 18px;
                line-height: 25px; }
            .body2 {
                font-size: 14px; } }
        .bor {
            font-size: 24px; } }

    .Techno {
        .whiteTwo {
            font-size: 35px; }
        .whiteSubtitle2 {
            font-size: 25px;
            line-height: 25px; } }
    .Technologies {
        margin: 50px 0 80px 0;
        .sub {
            font-size: 20px; }
        .whiteElevenButton1 {
            font-size: 11px; } }
    .rewiew {
        & .whiteTwo {
            font-size: 30px;
            margin-bottom: 20px; }
        & .whiteSubtitle2 {
            font-size: 25px; }
        & .clients-review {
            display: none; }
        & .whiteSubtitle1 {
            font-size: 25px; }
        & .whiteFiveSubtitle3 {
            font-size: 18px; }
        & .WhiteThree {
            font-size: 22px; }
        & ul {
            margin-top: 20px;
            & .whiteSevenBody1 {
                font-size: 18px; } }
        & .clents {
            margin-bottom: 100px;
            & .bor {
                margin-top: 20px; } }
        & .rewiew-body {
            margin-top: 75px !important;
            padding: 11px 0 100px 0; } }

    .request {
        .forms {
            padding-right: 10px;
            h2 {
                font-size: 32px;
                margin-top: 34px;
                margin-bottom: 28px; }
            .whiteSubtitle1 {
                font-size: 22px; }

            select {
                width: 100%;
                font-size: 16px;
                margin-bottom: 10px; }
            input {
                color: #fff;
                font-size: 16px;
                outline: none; } } }


    .Vacancies {
        .whiteElevenButton1 {
            font-size: 18px; }
        .formFront {

            form {
                border: 1px solid #8300C1; }
            input {
                width: 100px; } }
        .upload {
            width: 100%; }
        .bac-item {
            width: 100%; }
        .bacckend {
            display: none; }
        .items {
            display: none; }
        .frontItems {
            display: none; }
        .frontItem {
            width: 100%; }
        .double {
            display: block; } }
    .Footer {
        ul {
            list-style-type: none; }
        .footerss {
            display: flex; }
        .fotMenu {
            margin-top: 8.4%;
            ul {
                display: flex;
                flex-direction: column;
                list-style-type: none;
                li {
                    padding-bottom: 20px; } }
            a {
                font-size: 18px; } }
        .contact {}
        .footerss {
            display: block; }
        .foot-text:hover {
            color: #fff;
            text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1; } } }

@media screen and (min-width: 375px) {
    .Create {
        padding: 270px 0 50px 0;
        .picture {
            padding-top: 0px; } } }

@media (max-width: 575px) {
    .block-port {
        width: 100%; }
    .fabr {
        width: 60%; }
    .Footer {
        .footer-bot div {
            justify-content: center;
            align-items: center; } } }
@media (min-width: 576px) {
    .Header {
        .logo {
            h2 {
                font-size: 30px;
                padding-left: 34px; } }
        .lang {
            display: flex;
            margin-top: 10px;
            & a {
                padding-right: 15px; } }
        .lang-body {} }


    .Create {
        padding: 180px 0 50px 0;
        height: -webkit-fill-avalible;
        .whiteSubtitle1 {
            font-size: 18px; }
        .bor {
            padding: 9px 35px;
            font-size: 25px; }
        .picture {
            display: block;
            padding-top: 70px; } }



    .Technologies {
        margin: 50px 0 130px 0;
        .sparki {
            display: flex;
            padding: 20px 0; }
        .sparkis {
            display: none; }
        .sub {
            font-size: 20px; }
        .whiteElevenButton1 {
            font-size: 10px; } }
    .review {
        .contentDe {
            display: block; }
        .contentDev {
            width: 41.6%; }

        .whiteSubtitle1 {
            font-size: 20px; }
        .whiteElevenButton1 {
            font-size: 14px; } }
    .request {
        .forms {
            padding-right: 20px;
            h2 {
                font-size: 30px;
                margin-top: 34px;
                margin-bottom: 28px; }
            .ital {
                font-style: italic;
                color: #fff;
                font-size: 22px;
                line-height: 26px;
                font-weight: 300;
                margin-bottom: 2%; }
            .whiteSubtitle1 {
                font-size: 22px; }

            select {
                width: 50%;
                font-size: 18px; }
            input {
                font-size: 18px; } }
        .dev {
            margin-top: 50px; } }



    .Vacancies {
        .whiteElevenButton1 {
            font-size: 16px; }
        .formFront {
            form {
                border: 1px solid #8300C1; } }

        .bac-item {
            width: 100%; }
        .bacckend {
            display: none; }
        .frontItems {
            padding-top: 30px;
            display: block; }
        .frontItem {
            width: 50%; }
        .upload {
            width: 50%; }
        .double {
            display: none;
            form {
                border: 1px solid #8300C1;
                input {
                    width: 179px;
                    height: 20px; } } } }
    .Footer {
        .footerss {
            display: none; }
        .footer-item {
            display: flex; }
        .fotMenu {
            margin-top: 22%;
            ul {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                li {
                    padding-bottom: 20px; } }
            a {
                font-size: 18px; } }
        .whiteElevenButton1 {
            font-size: 14px; }
        .contact {
            p {
                font-size: 14px; }
            a {
                margin-bottom: 10px; }
            a:hover {
                color: #fff; } }
        .footerss {
            display: none; } } }

// Устройства Medium (планшеты, 768 пикселей и выше)
@media (min-width: 768px) {

    .Create {
        padding: 220px 0 100px 0;
        .whiteSubtitle1 {
            font-size: 20px; }
        .picture {
            display: flex;
            align-items: center;
            justify-content: center; }
        & .whiteOne {
            font-size: 42px;
            line-height: 1.5; }
        .bor {
            font-size: 25px; } }
    .Portfolio {
        .whiteTwo {
            font-size: 50px; }
        .whiteSevenBody1 {
            font-size: 18px; }
        .link-bor {
            margin-top: 1rem;
            margin-bottom: 0; }
        .achievements {
            .block-company {
                font-size: 68px;
                line-height: 1; }
            .whiteSubtitle1 {
                font-size: 25px;
                line-height: 25px; }
            .body2 {
                font-size: 16px; } }
        .bor {
            font-size: 25px; } }

    .Techno {
        .whiteTwo {
            font-size: 50px; }
        .whiteSubtitle2 {
            font-size: 30px;
            line-height: 36px; } }

    .Technologies {
        margin: 50px 0 240px 0;
        .sparki {
            display: flex;
            padding: 40px 0; }
        .sparkis {
            display: none; }
        .sub {
            font-size: 20px; }
        .whiteElevenButton1 {
            font-size: 12px; } }
    .rewiew {
        & .whiteTwo {
            font-size: 50px;
            margin-bottom: 20px; }
        & .whiteSubtitle2 {
            font-size: 30px; }
        & .image-review-two {
            display: block;
            padding-top: 50px; }
        & .clients-review {
            display: none; }
        & .whiteSubtitle1 {
            font-size: 30px; }
        & .whiteFiveSubtitle3 {
            font-size: 25px; }
        & .WhiteThree {
            font-size: 30px; }
        & ul {
            margin-top: 20px;
            & .whiteSevenBody1 {
                font-size: 18px;
                padding-left: 12px; } }
        & .rewiew-body {
            padding: 11px 0 60px 0; }
        & .clents {
            margin-bottom: 150px;
            & .bor {
                margin-top: 20px; } } }

    .request {
        .forms {
            padding-right: 30px;
            h2 {
                font-size: 50px;
                line-height: 1.2;
                margin-top: 34px;
                margin-bottom: 28px; }
            .ital {
                font-style: italic;
                color: #fff;
                font-size: 30px;
                line-height: 36px;
                font-weight: 300;
                margin-bottom: 2%; }
            .whiteSubtitle1 {
                font-size: 30px; }

            select {
                width: 50%;
                font-size: 18px; }
            input {
                font-size: 18px; } } }


    .Vacancies {
        .whiteElevenButton1 {
            font-size: 16px; }
        .formFront {
            form {
                border: 1px solid #8300C1; } } }

    .Footer {
        .fotMenu {
            margin-top: 8.4%;
            ul {
                display: flex;
                flex-direction: column;
                li {
                    padding-bottom: 20px; } }
            a {
                font-size: 18px; } }
        .contact {} } }

// Устройства Large (настольные компьютеры, 992 пикселей и выше)
@media (min-width: 992px) {
    .Header {
        .menu {
            display: flex; }
        .burger-menu {
            display: none;
            ul {
                padding-left: 0 !important; } }
        .lang-body {
            position: inherit; }
        .lang {
            display: flex;
            font-size: 14px; }
        .whiteElevenButton1 {
            font-size: 16px;
            padding-right: 15px; } }
    .Create {
        .whiteSubtitle1 {
            font-size: 20px; }
        .picture {
            padding-top: 0; }
        & .whiteOne {
            font-size: 48px;
            line-height: 60px; } }
    .Portfolio {
        & .whiteTwo {
            font-size: 40px;
            line-height: 1.1; }
        & .whiteSevenBody1 {
            font-size: 18px; }
        & .achievements {
            margin-top: 0; } }

    .Technologies {
        margin: 50px 0 400px 0;
        .sparki {
            display: flex; }
        .sparkis {
            display: none; }
        .whiteElevenButton1 {
            font-size: 14px; } }
    .rewiew {
        & .image-review-two {
            display: none; }
        & .clients-review {
            display: block; }
        & .coin-image {
            padding-bottom: 25px; }
        & .whiteSubtitle1 {
            font-size: 27px; }
        & .whiteFiveSubtitle3 {
            font-size: 18px;
            padding-bottom: 10px; }
        & .rewiew-body {
            & .WhiteThree {
                font-size: 25px;
                padding-top: 15px; } } }

    .request {
        .forms {
            h2 {
                font-size: 40px;
                margin-top: 34px;
                margin-bottom: 28px; }
            .ital {
                font-style: italic;
                color: #fff;
                font-size: 30px;
                line-height: 36px;
                font-weight: 300;
                margin-bottom: 2%; }
            .whiteSubtitle1 {
                font-size: 30px; }

            select {
                font-size: 18px; } }
        .dev {
            display: flex;
            align-items: center; } }
    .Vacancies {
        .bacckend {
            display: block; }
        .bac-item {
            width: 50%; } }

    .Footer {
        .fotMenu {
            margin-top: 12.2%;
            ul {
                flex-direction: row; }
            a {
                font-size: 18px;
                padding-right: 15px; } }
        .contact {
            p {
                font-size: 14px; } } } }

// Устройства X-Large (большие настольные компьютеры, 1200 пикселей и выше)
@media (min-width: 1200px) {
    .Header {
        .burger-menu {
            display: none; }
        .whiteElevenButton1 {
            font-size: 18px;
            padding-right: 15px; } }
    .Create {
        .picture {
            display: block; }

        .whiteSubtitle1 {
            font-size: 25px; } }


    .Technologies {

        .sparki {
            display: flex; }
        .sparkis {
            display: none; }
        .whiteElevenButton1 {
            font-size: 16px; } }
    .rewiew {
        & .coin-image {
            padding-bottom: 47px; }
        & .whiteSubtitle1 {
                font-size: 30px; }
        & .whiteFiveSubtitle3 {
            font-size: 25px;
            padding-bottom: 15px; } }

    .request {
        .forms {
            h2 {
                font-size: 50px;
                color: #fff;
                font-weight: bold;
                margin-top: 44px;
                margin-bottom: 38px; }
            .ital {
                font-style: italic;
                color: #fff;
                font-size: 30px;
                line-height: 36px;
                font-weight: 300;
                margin-bottom: 2%; }
            .whiteSubtitle1 {
                font-size: 30px; } } }

    .Vacancies {
        .whiteElevenButton1 {
            font-size: 14px; }
        .formFront {
            form {
                border: 1px solid #8300C1; } } }

    .Footer {
        ul {
            margin-top: 13%; }
        .contact {}
        .social {}
        .fotMenu {
            margin-top: 8.3%;
            a {
                font-size: 18px; } } } }
@media (min-width: 1331px) {
    .request {
        .forms {
            width: 50%; } } }



// Устройства XX-Large (большие настольные компьютеры, 1400 пикселей и выше)
@media (min-width: 1400px) {
    .Header {
        .lang {
            display: flex;
            margin-top: 10px; }
        .burger-menu {
            display: none; } }
    .whiteElevenButton1 {
                font-size: 18px; }
    .Technologies {
        .sparki {
            display: flex; }
        .sparkis {
            display: none; }
        .sub {
            font-size: 30px; }
        .whiteElevenButton1 {
            font-size: 18px; } }
    .review {
        .whiteSubtitle1 {
            font-size: 30px; }
        .whiteElevenButton1 {
            font-size: 18px; } }

    .Create {

        .whiteSubtitle1 {
            font-size: 30px; }
        .bor {
            padding: 11px 45px;
            font-size: 25px; }
        .picture {
            display: flex;
            align-items: center; }
        & .whiteOne {
            font-size: 50px;
            line-height: 76px; } }



    .request {
        .forms {
            width: 50%;
            .formControl {
                width: 100%; }
            h2 {
                font-size: 50px;
                color: #fff;
                font-weight: bold;
                margin-top: 54px;
                margin-bottom: 48px; }
            .ital {
                font-style: italic;
                color: #fff;
                font-size: 25px;
                font-weight: 300;
                margin-bottom: 3%; }
            .whiteSubtitle1 {
                font-size: 25px; }

            select {
                width: 50%;
                font-size: 18px; }
            input {
                font-size: 18px; } }

        .dev {
            display: flex;
            align-items: center;
            justify-content: center; } }



    .Vacancies {
        .whiteElevenButton1 {
            font-size: 18px; }
        .formFront {
            form {
                border: 1px solid #8300C1; } }
        .bacckend {
            display: block; }
        .bac-item {
            width: 50%; }
        .front {
            ul {
                display: none; }
            .frontItems {
                display: none; }
            .frontItem {
                width: 100%; } } }
    .Footer {
        ul {
            margin-top: 9%; }
        .contact {
            p {
                font-size: 18px; } }
        .social {}
        .fotMenu {
            margin-top: 8%;
            ul {
                flex-direction: row;
                li {
                    padding-bottom: 0; } }
            a {
                font-size: 18px; }
            a:hover {
                text-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1; } } } }

@media screen and (max-width: 1650px) {
    .request {
        .forms {
            padding-left: 120px; } } }

@media screen and (max-width: 1440px) {
    .request {
        .forms {
            padding-left: 70px; } } }
@media (max-width: 997px) {
    .Header {
        .menu {
            .whiteElevenButton1 {
                padding-right: 14px; } } } }
@media screen and (max-width: 991px) {
    .Techno {
        margin-top: 240px; } }
@media (max-width: 786px) {
    .Header {
        .logo {
            img {
                width: 223px; } } } }

@media screen and (max-width: 576px) {
    .Header {
        .logo {
            img {
                width: 144px; } } }
    .Create {
        .future {
            & .whiteOne {
                font-size: 36px;
                line-height: 45px; } } }
    .required {
        input {
            width: 100% !important; } }
    .request {
        .forms {
            padding-left: var(--bs-gutter-x, 0.75rem);
            textarea {
                width: 100%; } } } }
