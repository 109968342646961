.Team {
  padding-bottom: 160px;
  & .team-title {
    color: #fff;
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    font-style: italic; }
  & .team-card {
    margin-top: 91px;

    & img {
      margin: 0 auto;
      padding-bottom: 43px; }
    & .name-text {
      border: 1px solid #fff;
      height: 150px;
      box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1;
      padding: 20px 26px; } }
  & .whiteFiveSubtitle3 {
    word-break: keep-all; }
  & .name-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  & .name-text-long {
    width: 50%;
    margin: 0 auto; } }

@media screen and (max-width: 1199px) {
  .Team {
    .team-card {
      .name-text {
        height: 180px; } }
    & .name-text-long {
      width: 100%;
      margin: 0 auto; } } }


@media screen and (max-width: 375px) {
  .Team {
    & .whiteFiveSubtitle3 {
        font-size: 18px; }
    & .whiteSubtitle1 {
      font-size: 22px; }
    .team-card {
      .name-text {
        height: 150px; } } } }
