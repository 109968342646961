
.PortfolioPageStream {
  padding-bottom: 140px;

  & .first-image {
    &::before {
      content: url("../../assets/images/Ellipse.png");
      position: absolute;
      z-index: 0;
      top: 0;
      right: 50px; }

    & img {
      z-index: 1;
      max-width: 100%; } }

  & .page-body {
    padding-top: 220px; }

  & .product {
    margin: 100px 0; }

  & .image-stream {
    & img {
      &.logo {
        display: inline; }

      &.logo-text {
        display: inline;
        height: 20px; } }

    & .logo-container {
      padding-bottom: 42px; } }

  & .whiteSubtitle1 {
    padding-bottom: 30px; }

  ul {
    list-style-image: url("../../assets/images/Vector 91.png");

    li {
      padding-top: 18px; } }

  & .whiteSevenBody1 {
    font-size: 18px;
    padding-left: 12px; }

  & .WhiteThree {
    color: #ffffff;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    padding-top: 30px; } }

.Another {
  background-color: #8300C1;
  position: relative;
  .container-fluid {
      &:before, &:after {
        content: '';
        background-image: url("../../assets/images/crop-lines.svg");
        background-size: cover;
        width: 100%;
        height: 28px;
        position: absolute;

        &:before {
          top: -20px;
          left: 0; }

        &:after {
          bottom: -20px;
          left: 0;
          transform: rotate(180deg); } } }

  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;

    & .card-agro {
      padding-bottom: 30px; } } }


