.n-techno {
  padding-bottom: 79px;
  .tech-container {
    z-index: 1; }
  .tech-back-item div {
    z-index: 1;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center; }
  p {
    margin-bottom: 0; }
  & .whiteTwo {
    padding-top: 160px;
    margin-bottom: 20px; }
  & .tech-back-item {
    & .tabs-link {
      background-image: url("../../assets/images/Vector 116.svg");
      background-repeat: round;
      margin-bottom: 30px;
      padding: 25px 14px;
      height: 178px;
      cursor: default; }
    & .tabs-link:hover {
      background-image: url("../../assets/images/Vector 116_2.svg");
      background-repeat: round; } }
  & .page-title {
    padding: 60px 0; }
  & .customer {
    padding: 60px 0 130px 0;
    & .whiteSubtitle1 {
      padding-bottom: 30px; } }
  & img {
    max-width: 100%; }
  & .TechnoFriend {
    margin-top: 64px;
    position: relative;
    & .grup.nav-item {
      display: flex;
      justify-content: center; }
    &:before {
      content: "";
      background-image: url("../../assets/images/elips_1.png");
      width: 901px;
      height: 1176px;
      display: block;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 0;
      top: -350px;
      right: 0; }
    & .tab-content {
      z-index: 1;
      height: 400px;
      background-color: #8300C1;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        background-image: url("../../assets/images/crop-lines.svg");
        background-size: cover;
        width: 100%;
        height: 28px;
        top: -16px; }
      &:after {
        position: absolute;
        content: '';
        background-image: url("../../assets/images/crop-lines.svg");
        background-size: cover;
        width: 100%;
        height: 28px;
        transform: rotate(180deg);
        bottom: -17px; }
      & h3 {
        color: #ffffff;
        font-size: 36px;
        line-height: 43.2px;
        font-weight: 500;
        padding-bottom: 30px; }
      & .whiteFiveSubtitle3 {
        padding-bottom: 20px; } }
    & .tab-body {

      z-index: 1;
      padding: 60px 45px 60px 44px; }
    & .tabs-services {
      z-index: 1; }
    & .button-width, .tabs-services {
      display: flex; }



    & .nav-item {
      //.nav-link
      //  box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1
      //  height: 42px
      //  font-size: 18px
      //  line-height: 19px
      //  color: #8300C1
      //
      //.nav-link.active
      //    background-color: transparent
      //    border-color: #e9ecef #e9ecef #dee2e6
      //    isolation: isolate
      //    color: #fff
      //    box-shadow: 0 0 1em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1, 0 0 0.2em #8300C1
      //    border-bottom-right-radius: 0.25rem
      //    border-bottom-left-radius: 0.25rem
      //.nav-link:hover
      //  border-bottom-right-radius: 0.25rem
      //  border-bottom-left-radius: 0.25rem
      //& .nav-link
      //  width: 100%
      //  height: 100%
      //  display: flex
      //  align-items: center
      //  justify-content: center
      //  padding: 0
      //  margin-top: 0
      //  //width: 430px
      //  //height: 200px
      //  cursor: pointer
      //  font-size: 30px
      //  font-style: italic
      //  font-weight: 300
      //  color: #fff
      //  box-shadow: none
      //& .nav-link:hover
      //  border: none
      //  box-shadow: none
      //  outline: none
      //.nav-link:focus
      //  border: none !important
      //  box-shadow: none !important
 } }      //  outline: none !important

  .TechBack {
    position: relative;
    .button-tech {
      background-image: url("../../assets/images/Vector 114.svg");
      background-repeat: round;
      height: 178px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      max-width: 460px; }
    &-container::before {
      content: "";
      background-image: url("../../assets/images/ellipse_2.png");
      width: 627px;
      height: 660px;
      display: block;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 0;
      top: 30%;
      left: 0; }
    & .tabs-link {
        z-index: 1; }
    & .whiteTwo {
      margin-bottom: 60px; }
    & .tech-back-item {
      justify-content: space-between; }
    & .whiteSubtitle1 {
      padding: 5px; } }

  .Technologi {
    padding-top: 100px; } }
.bulli {
  img {
    height: 100%; } }


@media screen and (max-width: 1440px) {
  .n-techno {
    .TechnoFriend {
      .tabs-services {
        .nav-item {
          .nav-link {} } } } } }

@media screen and (max-width: 1199px) {
  .n-techno {
    .TechBack {
      margin-top: 50px; }
    .TechnoFriend {
      & .button-width, .tabs-services {
        display: initial; }
      & .nav-pills {
        padding: 0 40px; } }
    .TechBack {
      .whiteTwo {
        padding-top: 0; }
      &.container-fluid {
        padding: 0 40px; } } }
  .tech-back-item {
    .tabs-link {
      padding: 17px 40px !important;
      height: 190px !important; } } }

@media screen and (max-width: 991px) {
  .n-techno {
    .whiteSubtitle1 {
      font-size: 22px; }
    .whiteEightButton2 {
      font-size: 16px;
      letter-spacing: 1px; }
    .TechBack {
      .button-tech {
        &-first {
          margin-right: 14px; }
        &-second {
          margin-left: 14px; } } }
    .tech-back-item {
      .tabs-link {
        padding: 17px 20px !important;
        height: 140px !important; } }
    .TechnoFriend {
      .button-width {
        .button-top,
        .bottom-btn {}
        max-height: 178px;
        width: auto; }
      .tabs-services {
        .nav-item {
          .nav-link {
            width: auto;
            max-height: 178px; } } } } }
  .tabs-link {
    padding: 17px 14px !important; } }

@media screen and (max-width: 767px) {
  .n-techno {
    .whiteEightButton2 {
      font-size: 18px; }
    .TechBack {
      flex-direction: column;
      flex: 1 1 auto;
      .whiteTwo {
        text-align: center; }
      .whiteSubtitle1 {
        font-size: 30px; }
      .button-tech {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        max-width: 100%;
        &-first {
          margin-right: 0px; }
        &-second {
          margin-left: 0px;
          margin-top: 30px; } } }
    .tech-back-item {
      .tabs-link {
        height: 200px !important; } }
    .nav-item {
      margin-left: 15px !important; } } }


@media screen and (max-width: 578px) {
  .n-techno {
    .tech-back-item {
      .tabs-link {
        padding: 10px 60px !important; } }
    .TechnoFriend {
      .tab-content {
        h3 {
          font-size: 22px; } } }
    .whiteTwo {
      font-size: 35px;
      line-height: 1; }
    .whiteSubtitle2 {
      font-size: 22px; }
    .TechBack {
      flex-direction: column;
      flex: 1 1 auto;
      .whiteTwo {
        margin-bottom: 30px; }
      &.container-fluid {
        padding: 0 12px; }
      .button-tech {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &-first {
          margin-right: 0px; }
        &-second {
          margin-left: 0px;
          margin-top: 30px; } } }
    .tech-back-item {
      .tabs-link {
        height: 180px !important; } } } }
@media screen and (max-width: 475px) {
  .n-techno {
    .tech-back-item {
      .tabs-link {
        padding: 10px 30px!important; } }
    .whiteEightButton2 {
      font-size: 16px; }
    .TechBack {
      .whiteSubtitle1 {
        font-size: 22px; }
      .button-tech {
        height: 158px; } }
    .tech-back-item {
      .tabs-link {
        height: 158px; } }
    .tech-back-item {
      .tabs-link {
        height: 180px !important; } } } }
@media screen and (max-width: 475px) {
  .n-techno {
    .TechBack {
      .button-tech {
        height: 138px; } }
    .tech-back-item {
      .tabs-link {
        height: 138px; } }
    .tech-back-item {
      .tabs-link {
        height: 160px !important; } } } }
@media screen and (max-width: 375px) {
  .n-techno {
    .TechBack {
      .whiteSubtitle1 {
        font-size: 18px;
        padding: 0; } }
    .tech-back-item {
      .tabs-link {
        padding: 20px 12px !important; } }
    .whiteEightButton2 {
      font-size: 14px;
      line-height: 1.05; } } }
